<template>
  <div>
    <template v-if="detailEkskul.nama">
      <a-modal
      :visible="showConfirmEdit"
      :confirm-loading="loading.updateLoading"
      @ok="saveEditDetail"
      @cancel="showConfirmEdit = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black'
      }"
      okText="Edit"
      :okButtonProps="{
        props: {
          shape: 'round'
        }
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round'
        }
      }"
    >
      <h3 slot="title" style="color: #faad14;">
        <a-icon style="font-size: 1.2em" type="warning" /> EDIT {{detailEkskul.nama.toUpperCase()}} EXTRACURRICULAR
      </h3>
      <p>Are you sure to edit {{detailEkskul.nama}} details ?</p>
    </a-modal>
      <a-modal
      :visible="modalEditSupervisor"
      :confirm-loading="loading.updateLoading"
      @ok="saveEditSupervisor"
      @cancel="modalEditSupervisor = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black'
      }"
      okText="Edit"
      :okButtonProps="{
        props: {
          shape: 'round'
        }
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round'
        }
      }"
    >
      <h3 slot="title" style="color: #faad14;">
        <a-icon style="font-size: 1.2em" type="warning" /> EDIT {{detailEkskul.nama.toUpperCase()}}'S CURRENT SUPERVISOR
      </h3>
      <p>Are you sure to edit {{detailEkskul.nama}}'s current supervisor?</p>
    </a-modal>
      <h1>{{detailEkskul.nama}}'s Details</h1>
      <!-- {{detailClass}} -->
      <a-row :gutter="[32, 16]">
        <a-col :md="24" :lg="14">
          <div class="card-full">
            <div class="detail">
              <div class="d-lg-flex align-items-end">
                <a-menu class="mt-3 w-100 mr-3" v-model="current" mode="horizontal">
                  <a-menu-item key="detail">DETAILS</a-menu-item>
                  <a-menu-item key="list">PARTICIPANTS LIST</a-menu-item>
                </a-menu>
                <div class="d-lg-flex text-center mt-3" v-if="current[0] === 'detail'">
                  <a-button
                    shape="round"
                    class="ml-auto"
                    type="primary"
                    v-if="!isEdit"
                    @click.prevent="isEdit = true"
                    >
                    <a-icon type="form" />
                    EDIT</a-button>
                    <template v-else>
                      <a-button
                        type="danger"
                        shape="round"
                        icon="close"
                        class="mb-3 mb-lg-0 mr-0 mr-md-3"
                        @click.prevent="isEdit = false"
                        >CANCEL</a-button
                      >
                      <a-button
                        shape="round"
                        @click.prevent="validateEdit"
                        icon="save"
                        style="background-color: #41b883; color: white"
                        >SAVE</a-button
                      >
                    </template>
                </div>
              </div>

              <div v-if="current[0] === 'detail'" class="mt-5">
                <a-row :gutter="[32, 32]" class="ml-2">
                  <a-col :md="12" :sm="24">
                    <h3 class="type">Name</h3>
                    <p class="value" v-if="!isEdit">{{detailEkskul.nama || '-'}}</p>
                    <a-input class="my-3" v-model="editEkskul.name" v-else />
                  </a-col>
                  <a-col :md="12" :sm="24">
                    <h3 class="type">Participants</h3>
                    <p class="value" :class="isEdit ? 'my-3' : ''">{{participantsList.length}} students</p>
                  </a-col>
                </a-row>
                <a-row :gutter="[32, 32]" class="ml-2 mt-4">
                  <a-col :sm="24" :md="12">
                    <h3 class="type">Type</h3>
                    <!-- <p class="value" v-if="!isEdit">{{detailEkskul.tipe === 'opsional' ? 'Optional' : 'Required'}}</p> -->
                    <p class="value">{{detailEkskul.tipe === 'opsional' ? 'Optional' : 'Required'}}</p>
                    <!-- <template v-else>
                      <a-select
                        placeholder="Select Extracurricular Type"
                        v-model="editEkskul.type"
                        class="my-3 w-100"
                      >
                        <a-select-option value="wajib"> Required </a-select-option>
                        <a-select-option value="opsional">
                          Optional
                        </a-select-option>
                      </a-select>
                      <p>Level</p>
                      <a-checkbox-group class="w-100" v-model="editEkskul.daftarLevel">
                        <a-checkbox v-for="level in levels" :key="level" :value="level">
                          {{level}}
                        </a-checkbox>
                      </a-checkbox-group>
                    </template> -->
                  </a-col>
                  <a-col :sm="24" :md="12">
                    <h3 class="type">Description</h3>
                    <p class="value text-justify" v-if="!isEdit">{{detailEkskul.deskripsi || '-'}}</p>
                    <a-textarea
                      class="my-3"
                      v-model="editEkskul.desc"
                      v-else
                      allow-clear
                      :autoSize="{ minRows: 8 }"
                    />
                  </a-col>
                </a-row>
              </div>
              <div v-else class="mt-4">
                <a-table
                  class="table-scroll my-3"
                  :pagination="{ pageSize: 25, hideOnSinglePage: true }"
                  :columns="column"
                  :data-source="participantsList"
                ></a-table>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :md="24" :lg="10">
          <div class="card-full">
            <div class="detail">
              <div class="d-md-flex align-items-center">
                <div class="cui__utils__heading mb-0">
                  <strong>Supervisor Record</strong>
                </div>
                <a-button
                    shape="round"
                    class="ml-auto"
                    type="primary"
                    v-if="!isEditSupervisor"
                    @click.prevent="editCurrentSupervisor"
                    >
                    <a-icon type="form" />
                    EDIT CURRENT SPV</a-button>
                <template v-else>
                  <div class="ml-auto">
                      <a-button
                        type="danger"
                        shape="round"
                        icon="close"
                        class="mb-3 mb-lg-0 mr-0 mr-md-3"
                        @click.prevent="isEditSupervisor = false"
                        >CANCEL</a-button
                      >
                      <a-button
                        shape="round"
                        @click.prevent="modalEditSupervisor = true"
                        icon="save"
                        style="background-color: #41b883; color: white"
                        >SAVE</a-button
                      >
                  </div>
                    </template>
              </div>
              <!-- <div class="text-muted mb-3">You can change Zoom ID here</div> -->
              <hr />
              <template v-if="supervisorsList.length">
                <div class="d-md-flex w-100 mb-3" v-for="semester in supervisorsList" :key="semester.id">
                  <template v-if="semester.status === 'aktif'">
                    <template>
                      <div v-if="!isEditSupervisor">{{semester.pembina_ekstrakurikulers.length ? semester.pembina_ekstrakurikulers[0].guru.nama : '-'}}</div>
                      <a-select @change="handleChangeCurrentSpv" v-else class="w-100 mr-5" :value="editEkskul.currentSupervisor">
                        <a-select-option v-if="currentSupervisor.pembina_ekstrakurikulers.length" :value="currentSupervisor.pembina_ekstrakurikulers[0].guru.id">{{currentSupervisor.pembina_ekstrakurikulers.length ? currentSupervisor.pembina_ekstrakurikulers[0].guru.nama : '-'}}</a-select-option>
                        <a-select-option v-for="teacher in teachersEditSpvList" :key="teacher.id" :value="teacher.id">{{teacher.nama}}</a-select-option>
                      </a-select>
                      <div class="ml-auto">{{semester.tahun}} {{semester.semester}} (current)</div>
                    </template>
                  </template>
                  <template v-else>
                    <div>{{semester.pembina_ekstrakurikulers.length ? semester.pembina_ekstrakurikulers[0].guru.nama : '-'}}</div>
                    <div class="ml-auto">{{semester.tahun}} {{semester.semester}}</div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
const column = [
  {
    title: 'Student',
    // width: '100%',
    dataIndex: 'Name',
    key: 'Name',
    ellipsis: true,
  },
  {
    title: 'Class',
    // width: '100%',
    dataIndex: 'Class',
    key: 'Class',
  },
  // {
  //   title: 'Status',
  //   // width: '100%',
  //   dataIndex: 'Status',
  //   key: 'Status',
  // },
]

export default {
  data() {
    return {
      current: ['detail'],
      detailEkskul: {},
      participantsList: [],
      listOfWaliKelas: [],
      listOfTahunAjaran: [],
      students: '',
      waliKelas: '',
      tahunAjaran: '',
      classOfStudent: '',
      loadingUpdateProfile: false,
      loadingUpdatePassword: false,
      loadingDelete: false,
      newPassword: '',
      newVerifyPassword: '',
      isEdit: false,
      editEkskul: {
        type: '',
        name: '',
        desc: '',
        daftarLevel: [],
        currentSupervisor: null,
      },
      column,
      showConfirmEdit: false,
      loading: {
        updateLoading: false,
      },
      levels: ['VII', 'VIII', 'IX'],
      isEditSupervisor: false,
      teachersEditSpvList: [],
      modalEditSupervisor: false,
      supervisorsList: [],
    }
  },
  methods: {
    handleChangeCurrentSpv(val) {
      this.editEkskul.currentSupervisor = val
    },
    // changeCurrentSpv(val) {
    //   // console.log(val)
    //   this.editEkskul.currentSupervisor = val
    // },
    saveEditSupervisor() {
      const idPembina = this.editEkskul.currentSupervisor
      const idEkskul = this.$route.params.id
      this.loading.updateLoading = true
      this.$store.dispatch('admin/EDIT_CURRENT_SUPERVISOR_OF_EXTRACURRICULAR', { idPembina, idEkskul })
        .then(_ => {
          this.loading.updateLoading = false
          this.modalEditSupervisor = false
          this.isEditSupervisor = false
          this.fetchData(idEkskul)
        })
    },
    fetchTeacherNotSupervisorEkskul() {
      this.$store.dispatch('admin/FETCH_TEACHER_NOT_EXTRACURRICULAR_SUPERVISOR_LIST')
        .then(data => {
          this.teachersEditSpvList = data
          // console.log(this.teachersEditSpvList)
          this.editEkskul.currentSupervisor = this.currentSupervisor.pembina_ekstrakurikulers.length ? this.currentSupervisor.pembina_ekstrakurikulers[0].guru.id : this.editEkskul.currentSupervisor
          // this.isEditSupervisor = true
        })
    },
    async editCurrentSupervisor() {
      await this.fetchTeacherNotSupervisorEkskul()
      this.isEditSupervisor = true
    },
    validateEdit() {
      if (!this.editEkskul.name) {
        return this.$notification.error({
          message: 'Sorry.',
          description: 'Name must be filled.',
        })
      }
      this.showConfirmEdit = true
    },
    saveEditDetail() {
      const id = this.$route.params.id
      this.loading.updateLoading = true
      const savedData = {
        tipe: this.editEkskul.type,
        nama: this.editEkskul.name,
        deskripsi: this.editEkskul.desc,
      }
      const { daftarLevel } = this.editEkskul
      this.$store.dispatch('admin/EDIT_EKSKUL_GENERAL_DATA', { idEkskul: id, savedData, daftarLevel })
        .then(_ => {
          setTimeout(() => {
            this.$notification.success({
              message: 'Success',
              description:
                'Extracurricular has been updated successfully',
            })
          }, 100)
          this.fetchData(id)
        })
    },
    fetchData(id) {
      this.$store.dispatch('admin/FETCH_EKSKUL_BY_ID', { id })
        .then(data => {
          this.isEdit = false
          this.detailEkskul = data.ekstrakurikuler
          this.supervisorsList = data.pembina
          this.fetchParticipantList(id)
          // this.fetchTeacherNotSupervisorEkskul()
          this.showConfirmEdit = false
          this.loading.updateLoading = false
        })
      // this.$store.dispatch('admin/FETCH_WALI_KELAS')
      //   .then(data => {
      //     this.listOfWaliKelas = data
      //   })
      // this.$store.dispatch('admin/FETCH_TAHUN_AJARAN')
      //   .then(res => {
      //     this.listOfTahunAjaran = res.dataTable
      //   })
    },
    fetchParticipantList(id) {
      this.$store.dispatch('extracurricular/GET_EXTRACURRICULAR_PARTICIPANTS_BY_ID_EKSKUL', { idEkskul: id })
        .then(data => {
          // console.log(data, 'list')
          this.participantsList = data.map(participant => {
            return {
              Name: participant.kelas_tahun_ajaran.murid.nama,
              Class: participant.kelas_tahun_ajaran.kela.tingkat + '-' + participant.kelas_tahun_ajaran.kela.simbol,
              key: participant.kelas_tahun_ajaran.murid.id,
              idMMurid: participant.kelas_tahun_ajaran.murid.id,
              idPeserta: participant.kelas_tahun_ajaran.id,
            }
          })
        })
    },
    handleChangeWaliKelas(value) {
      this.detailClass.id_guru = value
    },
    handleChangeTahunAjaran(value) {
      this.detailClass.id_tahun_ajaran = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    updateProfile() {
      this.loadingUpdateProfile = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to update {this.detailClass.tingkat}-{this.detailClass.simbol} information ?</div>
        ),
        onOk: () => {
          this.loadingUpdateProfile = false
          this.$store.dispatch('admin/UPDATE_CLASS', { data: this.detailClass })
            .then(res => {
              if (res === 200) {
                this.fetchData(this.detailClass.id)
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.detailClass.tingkat}-${this.detailClass.simbol} information has been updated`,
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.detailClass.tingkat}-${this.detailClass.simbol} information has not been updated`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingUpdateProfile = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update',
      })
    },
  },
  mounted() {
    const id = this.$route.params.id
    this.fetchData(id)
  },
  watch: {
    isEdit(newVal) {
      if (newVal) {
        // this.fetchTeacherNotSupervisorEkskul()
        // console.log(this.editEkskul)
        this.editEkskul = {
          ...this.editEkskul,
          name: this.detailEkskul.nama,
          type: this.detailEkskul.tipe,
          desc: this.detailEkskul.deskripsi,
        }
      }
    },
  },
  computed: {
    currentSupervisor() {
      return this.supervisorsList.find(semester => semester.status === 'aktif')
    },
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}

.detail .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}
</style>
